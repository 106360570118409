$('.scroll-to').click(function (e) {
  e.preventDefault();
  document.getElementById(this.dataset.scrollTo).scrollIntoView({
    behavior: 'smooth'
  });
})

$(document).on('click.modal', 'a[rel~="modal:open"]', function() {
  $('#youtube-iframe').attr('src', this.dataset.url);
});

$(document).on('click.modal', 'a[rel~="modal:close"]', function () {
  $('#youtube-iframe').attr('src', '');
});
